import React, { useEffect } from "react";
import "./App.css";
import Footer from "./Footer";
import AddPlace from "./places/AddPlace";
import EditPlace from "./places/EditPlace";
import AddBigEvent from "./big-events/AddBigEvent";
import EditBigEvent from "./big-events/EditBigEvent";
import Header from "./Header";
import Login from "./Login";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useToken from "./hooks/useToken";
import { QueryClientProvider, QueryClient } from "react-query";
import TerminateAccount from './TerminateAccount';
import InformPrivacyPolicyChange from './InformPrivacyPolicyChange';
import InformTermsChange from './InformTermsChange';
import InformCookiePolicyChange from './InformCookiePolicyChange';
//import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    let tokenExpiration;
    if (token) {
      console.log(token);
      const now = new Date().getTime();
      console.log(now);
      const expiresIn = token.exp - now;
      const clearExpiredToken = () => {
        setToken(undefined);
        localStorage.removeItem("token");
        localStorage.removeItem("exp");
      };
      console.log(expiresIn);
      tokenExpiration = setTimeout(clearExpiredToken, expiresIn);
    }
    return () => {
      if (tokenExpiration) {
        console.log("clear timeout");
        clearTimeout(tokenExpiration);
      }
    };
  }, [token, setToken]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {token ? <Header token={token} setToken={setToken} /> : null}
        <Routes>
          <Route
            path="/"
            element={
              token ? <Home token={token} /> : <Login setToken={setToken} />
            }
          />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/terminate-account" element={token ? <TerminateAccount token={token} /> : <Login setToken={setToken} />} />
          <Route path="/inform-changes-privacy-policy" element={token ? <InformPrivacyPolicyChange token={token} /> : <Login setToken={setToken} />} />
          <Route path="/inform-changes-terms" element={token ? <InformTermsChange token={token} /> : <Login setToken={setToken} />} />
          <Route path="/inform-changes-cookie-policy" element={token ? <InformCookiePolicyChange token={token} /> : <Login setToken={setToken} />} />
          <Route
            path="/addPlace"
            element={
              token ? <AddPlace token={token} /> : <Login setToken={setToken} />
            }
          />
          <Route
            path="/editPlace/:placeId"
            element={
              token ? (
                <EditPlace token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
          <Route
            path="/addBigEvent"
            element={
              token ? (
                <AddBigEvent token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
          <Route
            path="/editBigEvent/:bigEventId"
            element={
              token ? (
                <EditBigEvent token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
        </Routes>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
