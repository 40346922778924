import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography"; // Import Typography

const ColorHomeIcon = styled(HomeIcon)(({ theme }) => ({
  color: "#ffffff",
  "&:hover": {
    color: "#000000",
  },
}));

export default function AppBarHeader({ token, setToken }) {
  const doLogout = () => {
    setToken(undefined);
    console.log("remove from storage");
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        sx={{
          height: 90,
          background: "#1EABF1",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            height: 90,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/">
              <IconButton aria-label="home">
                <ColorHomeIcon />
              </IconButton>
            </Link>
            <Typography
              variant="h2"
              color="#ffffff"
              fontSize={20} // Adjust fontSize if needed
              fontFamily="Trueno Light"
              sx={{ marginRight: 2 }}
            >
              Real socializing.
            </Typography>
          </Box>
          <Button
            color="inherit"
            onClick={doLogout}
            sx={{
              textTransform: "none",
              "&:hover": {
                color: "#000000",
              },
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
