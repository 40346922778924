import React from "react";
import logo from "../joinlogo.png";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import EditPlaceForm from "./EditPlaceForm";
import { usePlaceData } from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const onSuccess = (query) => (data) => {
  switch (query) {
    case "places-get":
      console.log("Places fetched", data);
      break;
    default:
      console.log("fetch places success");
  }
};

const onError = (query) => (error) => {
  switch (query) {
    case "places-get":
      console.log("Places error", error);
      break;
    default:
      console.log("some error on fetching places");
  }
};

export default function EditPlace({ token }) {
  const { placeId } = useParams();
  const place = usePlaceData(onSuccess, onError, token.token, placeId);

  console.log(place);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleDiscard = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #d4ebf2, #f2f2f2)",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "90%",
          margin: "0 auto",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          spacing={3}
          alignItems="center"
          marginTop={4}
          marginBottom={20}
          paddingInline={4}
        >
          <img src={logo} alt="logo" style={{ width: 200 }} />
          <Typography
            variant="h2"
            align="center"
            color="#1EABF1"
            fontSize={30}
            fontFamily="Trueno Light"
            sx={{ paddingBottom: 2 }}
          >
            Edit your Place
          </Typography>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            width="80%"
            sx={{ marginBottom: 2 }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ opacity: 0.6 }}
              onClick={handleDiscard}
            >
              Discard Changes
            </Button>
          </Stack>
          <Divider
            sx={{
              width: "85%",
              backgroundColor: "rgba(30, 171, 241, 0.2)",
              height: "1px",
            }}
          />
          {place.isSuccess ? (
            <EditPlaceForm token={token.token} place={place.data} />
          ) : place.isError ? (
            <h2>{place.error.message}</h2>
          ) : (
            <CircularProgress sx={{ color: "#1EABF1" }} />
          )}
        </Stack>
      </Box>
    </Box>
  );
}
