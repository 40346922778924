import { useState } from "react";

export default function useToken() {
  function getToken() {
    const tokenString = localStorage.getItem("token");
    const expiryDate = parseInt(localStorage.getItem("exp"));
    if (tokenString) {
      const now = new Date().getTime();
      const expiresIn = expiryDate - now;

      if (expiresIn <= 0) {
        localStorage.removeItem("token");
        localStorage.removeItem("exp");
        return undefined;
      } else return { token: tokenString, exp: expiryDate };
    } else return undefined;
  }

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (userToken) {
      const now = new Date().getTime();
      localStorage.setItem("token", userToken);
      localStorage.setItem("exp", now + 3600000);
      setToken({ token: userToken, exp: now + 3600000 });
    } else {
      setToken(undefined);
    }
  };

  return {
    token,
    setToken: saveToken,
  };
}
