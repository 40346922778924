// src/Home.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useBigEventsData, usePlacesData } from "./hooks/useFetch";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import ImageList from "@mui/material/ImageList";
import { StyledButton } from "./StyledComponents";
import PlaceListTile from "./places/PlaceListTile";
import BigEventListTile from "./big-events/BigEventListTile";
import comparePlacesOrEvents from "./utils/comparePlacesOrEvents";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AdminActions from "./AdminActions"; // Import the new AdminActions component

const onSuccess = (query) => (data) => {
  switch (query) {
    case "places-get":
      console.log("Places fetched", data);
      break;
    default:
      console.log("fetch places success");
  }
};

const onError = (query) => (error) => {
  switch (query) {
    case "places-get":
      console.log("Places error", error);
      break;
    default:
      console.log("some error on fetching places");
  }
};

export default function Home({ token }) {
  const places = usePlacesData(onSuccess, onError, token.token);
  const bigEvents = useBigEventsData(onSuccess, onError, token.token);

  const [tabIndex, setTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPlaces = places.data
    ? places.data.filter((place) =>
        place.name.toLowerCase().includes(searchTerm)
      )
    : [];

  const filteredBigEvents = bigEvents.data
    ? bigEvents.data.filter((bigEvent) =>
        bigEvent.name.toLowerCase().includes(searchTerm)
      )
    : [];

  // Separate active and non-active items
  const activePlaces = filteredPlaces.filter(place => place.active);
  const nonActivePlaces = filteredPlaces.filter(place => !place.active);

  const activeBigEvents = filteredBigEvents.filter(bigEvent => bigEvent.active);
  const nonActiveBigEvents = filteredBigEvents.filter(bigEvent => !bigEvent.active);

  return (
    <Stack
      spacing={5}
      alignItems="center"
      marginTop={4}
      marginBottom={8}
      paddingInline={7}
      minHeight="calc(100vh - 280px)"
    >
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Places" />
        <Tab label="Big Events" />
        <Tab label="Admin Actions" />
      </Tabs>

      <TextField
        label={tabIndex === 0 ? "Search by Place Name" : tabIndex === 1 ? "Search by Big Event Title" : "Search Admin Actions"}
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{
          borderRadius: '15px', 
          '& .MuiOutlinedInput-root': {
            borderRadius: '15px',
          },
        }}
      />

      {tabIndex === 0 ? (
        <>
          {/* Places Tab Content */}
          <Stack
            spacing={5}
            width="100%"
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="h1"
              align="center"
              color="#1EABF1"
              fontSize={38}
              fontFamily="Trueno Light"
            >
              Places on Join
            </Typography>
            <Link to={"addPlace"} style={{ textDecoration: "none" }}>
              <StyledButton
                variant="contained"
                size="large"
                myColor="#1EABF1"
                hoverColor="#187DDA"
              >
                Add new Place
              </StyledButton>
            </Link>
          </Stack>
          {places.isLoading ? (
            <CircularProgress sx={{ color: "#1EABF1" }} />
          ) : places.isError ? (
            <h2>{places.error.message}</h2>
          ) : (
            <>
              <ImageList
                cols={4}
                gap={40}
                rowHeight={250}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {[...activePlaces].sort(comparePlacesOrEvents).map((place) => (
                  <PlaceListTile
                    key={place.placeId}
                    token={token}
                    name={place.name}
                    address={place.address}
                    placeId={place.placeId}
                    imageFileName={place.photos[0]}
                    isPromoted={place.isPromoted}
                    active={place.active}
                  />
                ))}
              </ImageList>
              <Divider
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(30, 171, 241, 0.2)",
                  height: "1px",
                }}
              />
              <Stack
                spacing={0}
                width="100%"
                alignItems="left"
                direction="row"
                justifyContent="start"
              >
                <Typography
                  variant="h6"
                  fontSize={22}
                  fontFamily="Trueno Light"
                >
                  Non Active Places
                </Typography>
              </Stack>
              <ImageList
                cols={4}
                gap={40}
                rowHeight={250}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {[...nonActivePlaces].sort(comparePlacesOrEvents).map((place) => (
                  <PlaceListTile
                    key={place.placeId}
                    token={token}
                    name={place.name}
                    address={place.address}
                    placeId={place.placeId}
                    imageFileName={place.photos[0]}
                    isPromoted={place.isPromoted}
                    active={place.active}
                  />
                ))}
              </ImageList>
            </>
          )}
        </>
      ) : tabIndex === 1 ? (
        <>
          {/* Big Events Tab Content */}
          <Stack
            spacing={5}
            width="100%"
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="h1"
              align="center"
              color="#1EABF1"
              fontSize={38}
              fontFamily="Trueno Light"
            >
              Big Events on Join
            </Typography>
            <Link to={"addBigEvent"} style={{ textDecoration: "none" }}>
              <StyledButton
                variant="contained"
                size="large"
                myColor="#1EABF1"
                hoverColor="#187DDA"
              >
                Add new Big Event
              </StyledButton>
            </Link>
          </Stack>
          {bigEvents.isLoading ? (
            <CircularProgress sx={{ color: "#1EABF1" }} />
          ) : bigEvents.isError ? (
            <h2>{bigEvents.error.message}</h2>
          ) : (
            <>
              <ImageList
                cols={4}
                gap={40}
                rowHeight={250}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {[...activeBigEvents].sort(comparePlacesOrEvents).map((bigEvent) => (
                  <BigEventListTile
                    key={bigEvent.bigEventId}
                    token={token}
                    name={bigEvent.name}
                    address={bigEvent.location}
                    bigEventId={bigEvent.bigEventId}
                    imageFileName={bigEvent.photos[0]}
                    isPromoted={bigEvent.isPromoted}
                    active={bigEvent.active}
                  />
                ))}
              </ImageList>
              <Divider
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(30, 171, 241, 0.2)",
                  height: "1px",
                }}
              />
              <Stack
                spacing={0}
                width="100%"
                alignItems="left"
                direction="row"
                justifyContent="start"
              >
                <Typography
                  variant="h6"
                  fontSize={22}
                  fontFamily="Trueno Light"
                >
                  Non Active Big Events
                </Typography>
              </Stack>
              <ImageList
                cols={4}
                gap={40}
                rowHeight={250}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {[...nonActiveBigEvents].sort(comparePlacesOrEvents).map((bigEvent) => (
                  <BigEventListTile
                    key={bigEvent.bigEventId}
                    token={token}
                    name={bigEvent.name}
                    address={bigEvent.location}
                    bigEventId={bigEvent.bigEventId}
                    imageFileName={bigEvent.photos[0]}
                    isPromoted={bigEvent.isPromoted}
                    active={bigEvent.active}
                  />
                ))}
              </ImageList>
            </>
          )}
        </>
      ) : (
        <AdminActions searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
      )}
    </Stack>
  );
}
