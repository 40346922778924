// src/AdminActions.js

import React from 'react';
import { Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const allActions = [
  { id: 1, label: 'Terminate An Account', path: '/terminate-account' },
  { id: 2, label: 'Inform Users of changes in Privacy Policy', path: '/inform-changes-privacy-policy' },
  { id: 3, label: 'Inform Users of changes in Terms and Conditions', path: '/inform-changes-terms' },
  { id: 4, label: 'Inform Users of changes in Cookie Policy', path: '/inform-changes-cookie-policy' },
];

export default function AdminActions({ searchTerm, handleSearchChange }) {
  const navigate = useNavigate();

  const filteredActions = allActions.filter(action =>
    action.label.toLowerCase().includes(searchTerm)
  );

  const handleButtonClick = (path) => {
    if (path) {
      navigate(path);
    } else {
      alert('Action does not have a defined path.');
    }
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      marginTop={4}
      marginBottom={8}
      paddingInline={7}
    >
      <Stack spacing={2}>
        {filteredActions.map(action => (
          <Button
            key={action.id}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleButtonClick(action.path)}
          >
            {action.label}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
