// src/InformPrivacyPolicyChange.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledTextField, StyledButton, } from "./StyledComponents";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";


export default function InformPrivacyPolicyChange({ token }) {
  const [policyChangesGr, setPolicyChangesGr] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [policyChangesEn, setPolicyChangesEn] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();



  const handleConfirmInform = async () => {
    setLoading(true); // Show loader

    const headers = {
      Accept: "*/*",
      token: token.token,
      "Content-Type": "application/json",
    };
    const body = {
      changes: policyChangesEn,
      changesGR: policyChangesGr,
      effectiveDate: effectiveDate,
    };

    try {

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/operations/update_pp`,
        body,
        { headers },
      );
      setLoading(false); // Hide loader
      alert('All users have been informed about the privacy policy changes.');
      setDialogOpen(false);
      setTimeout(() => {
        navigate('/');
      }, 2000); // Navigate after a short delay to allow user to see the success message
    }
    catch (error) {
      console.log(error)
      setLoading(false); // Hide loader
      setShowError(true); // Show error snackbar
    }

  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      marginTop={4}
      marginBottom={8}
      paddingInline={7}
    >
      <Typography variant="h1" align="center" color="primary" fontSize={38}>
        Inform Users of Privacy Policy Changes
      </Typography>
      <TextField
        label="Changes to the Current Policy(English)"
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        value={policyChangesEn}
        onChange={(e) => setPolicyChangesEn(e.target.value)}
      />
      <TextField
        label="Changes to the Current Policy(Greek)"
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        value={policyChangesGr}
        onChange={(e) => setPolicyChangesGr(e.target.value)}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant="h6" align="left" color="textSecondary">
        Effective Date
       </Typography>
        <DatePicker
          value={effectiveDate}
          onChange={(newValue) => {
            setEffectiveDate(newValue);
          }}
          renderInput={(params) => <StyledTextField {...params} />}
        />
      </LocalizationProvider>
      <StyledButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setDialogOpen(true)}
        disabled={!policyChangesEn || !policyChangesGr || !effectiveDate || loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        Done
      </StyledButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Informing Users</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To confirm informing all users via email, please type "Yes, I want to inform all users about these changes" below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Confirmation Text"
            fullWidth
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button disabled={confirmationText !== 'Yes, I want to inform all users about these changes'} onClick={handleConfirmInform} color="primary">
            Confirm
          </Button>
        </DialogActions>
        </Dialog><Snackbar
        open={showError}
        autoHideDuration={2000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          An error occurred while informing users about the changes.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          User have been informed about the changes.
        </Alert>
      </Snackbar>
    </Stack>
  );
}
