import React from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function ClearButton({ onClick }) {
  return (
    <IconButton
      aria-label="delete"
      onClick={onClick}
      style={{
        maxWidth: "40px",
        maxHeight: "40px",
        minWidth: "40px",
        minHeight: "40px",
        alignSelf: "center",
      }}
    >
      <ClearIcon />
    </IconButton>
  );
}
