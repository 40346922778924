import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Rating from "@mui/material/Rating";
import styled from "@emotion/styled";

export const StyledTextField = styled(TextField)({
  fontFamily: "Trueno Light",
  backgroundColor: "#F0F0F0",
  borderRadius: 20,
  boxShadow: "none",
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: 20,
    },
    "&:hover fieldset": {
      borderColor: "#1EABF1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1EABF1",
    },
  },
});

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "myColor" && prop !== "hoverColor",
})(({ theme, myColor, hoverColor, width }) => ({
  color: "#ffffff",
  fontFamily: "Trueno Light",
  textTransform: "none",
  width: width || "auto",
  background: `linear-gradient(to bottom right, ${myColor} 30%, ${hoverColor} 90%)`,
  boxShadow: "none",
  borderRadius: 30,
  "&:hover": {
    background: `linear-gradient(to bottom right, ${hoverColor} 30%, ${myColor} 90%)`,
    boxShadow: "none",
  },
}));

export const StyledSelect = styled(Select)({
  backgroundColor: "#F0F0F0",
  borderRadius: 20,
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1EABF1",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1EABF1",
  },
});

export const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1EABF1",
  },
  "& .MuiRating-iconHover": {
    color: "#1EABF1",
  },
});
