import React, { useState } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useMutation, useQueryClient } from "react-query";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AspectRatio from "@mui/joy/AspectRatio";
import { usePlaceFile } from "../hooks/useFetch";
import axios from "axios";
import { Buffer } from "buffer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Stack from "@mui/material/Stack";
import PromotedIcon from "../common/PromotedIcon";

const onSuccess = (query) => (data) => {
  switch (query) {
    case "places-get":
      console.log("Pic fetched", data);
      break;
    default:
      console.log("fetch pic success");
  }
};

const onError = (query) => (error) => {
  switch (query) {
    case "places-get":
      console.log("Pic error", error);
      break;
    default:
      console.log("some error on fetching pic");
  }
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PlaceListTile({
  token,
  name,
  address,
  placeId,
  imageFileName,
  isPromoted,
  active,
}) {
  const [dialogState, setDialogState] = useState({ open: false, text: "" });

  const pic = usePlaceFile(onSuccess, onError, token.token, imageFileName);

  const queryClient = useQueryClient();

  const deletePlace = useMutation(
    (placeId) => {
      const headers = {
        Accept: "*/*",
        token: token.token,
      };

      return axios.delete(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/places/delete_place/${placeId}`,
        {
          headers,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("places-get");
      },
    },
  );

  const handleTextChange = (event) => {
    setDialogState({ ...dialogState, text: event.target.value });
  };

  const handleClickOpen = (placeId, placeName) => {
    setDialogState({ open: true, text: "" });
  };

  const handleClose = () => {
    setDialogState({ open: false, text: "" });
  };

  const handleDelete = (placeId) => {
    console.log("deleting place");
    handleClose();
    console.log("deleting place");
    deletePlace.mutate(placeId);
  };

  return (
    <div>
      <ImageListItem>
        <Link to={`/editPlace/${placeId}`}>
          <AspectRatio
            objectFit="cover"
            ratio="6/5"
            sx={{
              width: 300,
              bgcolor: "background.level2",
              borderRadius: 10,
            }}
          >
            {pic.isLoading ? (
              <Skeleton height="185px" highlightColor="#ffffff" />
            ) : (
              <img
                style={{ opacity: active ? null : 0.5 }}
                src={`data:image/png;base64,${Buffer.from(pic.data).toString(
                  "base64",
                )}`}
                alt={"Cannot be displayed..."}
              />
            )}
          </AspectRatio>
        </Link>
        <ImageListItemBar
          title={
            <Stack direction="row" alignItems="center">
              <span>{name}</span>
              {isPromoted ? <PromotedIcon /> : null}
            </Stack>
          }
          sx={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            backgroundColor: active ? null : "rgba(160, 15, 15, 0.7)",
          }}
          subtitle={address.el ?? address.en}
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.8)" }}
              aria-label={`delete ${name}`}
              onClick={() =>
                deletePlace.isLoading ? null : handleClickOpen(placeId, name)
              }
            >
              <DeleteIcon />
            </IconButton>
          }
        />
      </ImageListItem>
      <Dialog open={dialogState.open} onClose={handleClose}>
        <DialogTitle>Delete this place?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Unexpected bad things will happen if you don’t read this!
          </DialogContentText>
          <DialogContentText>
            This action cannot be undone. This will permanently delete {name}{" "}
            place, groups and events associated with it. Please type {name}/
            {placeId}/delete-this-place.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="delete-text"
            fullWidth
            variant="standard"
            onChange={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleDelete(placeId)}
            disabled={
              dialogState.text !== `${name}/${placeId}/delete-this-place`
            }
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={deletePlace.isError}
        autoHideDuration={2000}
        onClose={() => deletePlace.reset()}
      >
        <Alert
          onClose={() => deletePlace.reset()}
          severity="error"
          sx={{ width: "100%" }}
        >
          Delete failed!
        </Alert>
      </Snackbar>
    </div>
  );
}
