import dayjs from "dayjs";

const parseHour = (hourString) => {
  const [hours, minutes] = hourString.split(":");
  const now = new Date();
  now.setHours(parseInt(hours));
  now.setMinutes(parseInt(minutes));
  return dayjs(now);
};

const parseSchedule = (scheduleString) => {
  if (scheduleString) {
    const [open, close] = scheduleString.split("-");
    return { open: parseHour(open), close: parseHour(close) };
  } else return { open: null, close: null };
};

export default parseSchedule;
