const insertIntoSortedList = (sortedList, newNum) => {
  if (newNum === null) {
    return sortedList.slice(0, -1);
  }

  let result = [];
  let inserted = false;

  for (let i = 0; i < sortedList.length; i++) {
    const current = sortedList[i];
    if (newNum <= current && !inserted) {
      result.push(newNum);
      inserted = true;
      result.push(current);
    } else result.push(current);
  }

  return result.slice(0, -1);
};

export default insertIntoSortedList;
