import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import { StyledButton } from "../StyledComponents";

export default function VideoSelector({
  width,
  height,
  source,
  setSource,
  setVideoFile,
  setVidChanged,
  beforeDelete, // Function to be called before deleting the video
}) {
  const inputRef = useRef();
  const maxFileSizeInBytes = 12 * 1024 * 1024; // 12 MB in bytes
  const acceptedFileTypes = ["video/mp4", "video/quicktime"]; // MP4 and MOV mime types

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check the file type
    if (!acceptedFileTypes.includes(file.type)) {
      alert(
        `The video file ${file.name} is not a supported format. Only .mov and .mp4 files are allowed.`,
      );
      return; // Do not proceed with setting the source and video file
    }

    // Check the file size
    if (file.size > maxFileSizeInBytes) {
      alert(`The video file ${file.name} exceeds the 12 MB size limit.`);
      return; // Do not proceed with setting the source and video file
    }

    setVideoFile(file);
    const url = URL.createObjectURL(file);
    setSource(url);
    if (setVidChanged) setVidChanged(true);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const handleDelete = (event) => {
    if (beforeDelete) {
      beforeDelete(); // Call the function before deleting the video
    }
    setSource("");
    if (setVidChanged) setVidChanged(true);
  };

  console.log(source);

  return (
    <Stack spacing={4} direction="column" alignItems="center">
      {source ? (
        <StyledButton
          variant="contained"
          size="large"
          onClick={handleDelete}
          myColor="#e74c3c"
          hoverColor="#D14240"
        >
          Delete video
        </StyledButton>
      ) : (
        <StyledButton
          variant="contained"
          size="large"
          onClick={handleChoose}
          myColor="#1EABF1"
          hoverColor="#187DDA"
        >
          Tap to upload video
        </StyledButton>
      )}
      <div className="VideoInput">
        <input
          ref={inputRef}
          className="VideoInput_input"
          type="file"
          onChange={handleFileChange}
          accept=".mov,.mp4"
          style={{ display: "none" }}
        />
        {source && (
          <video
            className="VideoInput_video"
            width="100%"
            height={height}
            controls
            src={source}
          />
        )}
        {source ? <div className="VideoInput_footer">{source}</div> : null}
      </div>
    </Stack>
  );
}
