import "./App.css";
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledTextField, StyledButton } from "./StyledComponents";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import logo from "./joinlogo.png";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const canSubmit = [username, password].every(Boolean);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownOrUpPassword = (event) => {
    event.preventDefault();
  };

  const doLogin = async (username, password) => {
    setLoading(true);

    const headers = {
      Accept: "*/*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/auth/login`,
        { username: username, password: password },
        { headers },
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);
        setToken(response.data);
        if (location.pathname === "/login") navigate("/");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized");
          setWrongCredentials(true);
        }
        setLoading(false);
        setError(true);
        console.error("There was an error!", error);
      });

    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ background: "#1EABF1" }}
    >
      <Box
        width={350}
        height={350}
        display="flex"
        alignItems="center"
        justifyContent="center"
        boxShadow="0.3em 0.3em 1em rgba(0, 0, 0, 0.3)"
        borderRadius={10}
        sx={{ background: "#ffffff" }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            doLogin(username, password);
          }}
        >
          <Stack spacing={3} alignItems="center">
            <img src={logo} alt="logo" style={{ width: 100 }} />
            <FormControl fullWidth variant="outlined">
              {/* In case of wrong credentials*/}
              <StyledTextField
                error={wrongCredentials}
                id="username"
                placeholder="Username"
                variant="outlined"
                onChange={handleUsernameChange}
                value={username}
              ></StyledTextField>
            </FormControl>
            {/* Password is inserted here */}
            <FormControl fullWidth variant="outlined">
              <StyledTextField
                error={wrongCredentials}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownOrUpPassword}
                        onMouseUp={handleMouseDownOrUpPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Password"
              />
            </FormControl>
            {wrongCredentials ? (
              <p className="wrongcredentials">Wrong credentials</p>
            ) : null}
            {isLoading ? (
              <CircularProgress sx={{ color: "#1EABF1" }} />
            ) : (
              <StyledButton
                variant="contained"
                disabled={!canSubmit}
                type="submit"
                hoverColor="#187DDA"
              >
                Login
              </StyledButton>
            )}
          </Stack>
        </form>
        {/* In case of internal server error or communication fail the according warning is shown */}
        <Snackbar open={hasError} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Interal Server error
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default Login;
