const padWithZeros = (number) => {
  const numberString = number.toString();
  return numberString.length >= 2
    ? numberString
    : "0".repeat(2 - numberString.length) + numberString;
};

const dayHoursStrings = (day, suffix) =>
  day.open && day.close
    ? {
        [`hours${suffix}1`]: `${padWithZeros(day.open.$H)}:${padWithZeros(
          day.open.$m,
        )}`,
        [`hours${suffix}2`]: `${padWithZeros(day.close.$H)}:${padWithZeros(
          day.close.$m,
        )}`,
      }
    : {
        [`hours${suffix}1`]: null,
        [`hours${suffix}2`]: null,
      };

export default dayHoursStrings;
