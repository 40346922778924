import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { StyledRating } from "../StyledComponents";

export default function MyRating({ handleRatingChanged, rating, setRating }) {
  const [hover, setHover] = useState(-1);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <StyledRating
        name="half-rating"
        value={rating}
        precision={0.1}
        getLabelText={(value) => `${value}`}
        onChange={handleRatingChanged}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {rating !== 0 && (
        <Box sx={{ ml: 2 }}>{hover !== -1 ? hover : rating}</Box>
      )}
      <IconButton
        aria-label="delete"
        onClick={() => setRating(0)}
        style={{
          maxWidth: "40px",
          maxHeight: "40px",
          minWidth: "40px",
          minHeight: "40px",
          alignSelf: "center",
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
}
