import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ClearButton from "./ClearButton";

function roundToNearest5Minutes(time) {
  const minutes = time.minute();
  const roundedMinutes = Math.round(minutes / 5) * 5;
  return time.minute(roundedMinutes).second(0);
}

export default function TimeRangePicker({ value, setValue, day }) {
  const handleOpenTimeChange = (newValue) => {
    if (newValue) {
      const roundedTime = roundToNearest5Minutes(newValue);
      setValue({ ...value, open: roundedTime });
    } else {
      setValue({ ...value, open: null });
    }
  };

  const handleCloseTimeChange = (newValue) => {
    if (newValue) {
      const roundedTime = roundToNearest5Minutes(newValue);
      setValue({ ...value, close: roundedTime });
    } else {
      setValue({ ...value, close: null });
    }
  };

  return (
    <Stack spacing={2} width={600} direction="row" justifyContent="center">
      <Box
        width={150}
        sx={{
          fontSize: 15,
          fontFamily: "Montserrat Regular",
          alignSelf: "center",
          textAlign: "center", // Center the text horizontally
        }}
      >
        <Box sx={{ fontWeight: "bold" }}>{day}</Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={value.open}
          onChange={handleOpenTimeChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Opening Time",
              }}
            />
          )}
          minutesStep={5}
        />
      </LocalizationProvider>
      <Box
        sx={{
          fontSize: 15,
          fontFamily: "Montserrat Regular",
          alignSelf: "center",
        }}
      >
        to:
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          value={value.close}
          onChange={handleCloseTimeChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Closing Time",
              }}
            />
          )}
          minutesStep={5}
        />
      </LocalizationProvider>
      <ClearButton onClick={() => setValue({ open: null, close: null })} />
    </Stack>
  );
}
