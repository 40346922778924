import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StyledTextField } from "../StyledComponents";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function TimeRangePicker({ value, setValue }) {
  return (
    <Stack spacing={2} width={600} direction="row" justifyContent="start">
      <Box
        width={250}
        sx={{
          fontSize: 16,
          fontFamily: "Montserrat Regular",
          alignSelf: "center",
          fontWeight: "bold",
        }}
      >
        Event Dates
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value.open}
          maxDate={value.close}
          onChange={(newValue) => {
            setValue({ ...value, open: newValue });
          }}
          renderInput={(params) => <StyledTextField {...params} />}
        />
      </LocalizationProvider>
      <Box
        sx={{
          fontSize: 15,
          fontFamily: "Montserrat Regular",
          alignSelf: "center",
        }}
      >
        to:
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value.close}
          minDate={value.open}
          onChange={(newValue) => {
            console.log(newValue);
            setValue({ ...value, close: newValue });
          }}
          renderInput={(params) => <StyledTextField {...params} />}
        />
      </LocalizationProvider>
    </Stack>
  );
}
