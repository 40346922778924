export default function comparePlacesOrEvents(a, b) {
  if (
    !b.active ||
    (a.active && a.position && (!b.position || a.position < b.position))
  ) {
    return -1;
  } else if (
    !a.active ||
    (b.active && b.position && (!a.position || a.position > b.position))
  ) {
    return 1;
  }
  return 0;
}
