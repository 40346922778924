import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImageUploading from "react-images-uploading";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import { StyledButton } from "../StyledComponents";

export default function ImageSelector({
  images,
  setImages,
  maxNumber,
  description,
  setDidChange,
}) {
  const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

  const onChange = (imageList) => {
    const validImages = [];

    imageList.forEach((image) => {
      const fileExtension = image.file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'heic'];

      if (image.file.size <= maxFileSizeInBytes && allowedExtensions.includes(fileExtension)) {
        validImages.push(image);
      } else {
        alert(`Image ${image.file.name} is either too large or not a valid file type.`);
      }
    });

    setImages(validImages);
    if (setDidChange) setDidChange(true);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedImages = Array.from(images);
    const [movedImage] = reorderedImages.splice(source.index, 1);
    reorderedImages.splice(destination.index, 0, movedImage);

    setImages(reorderedImages);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack spacing={4.2} direction="column" alignItems="center">
              <Stack spacing={2} direction="row" justifyContent="center">
                <StyledButton
                  variant="contained"
                  size="large"
                  onClick={onImageUpload}
                  disabled={imageList.length >= maxNumber}
                  myColor={isDragging ? "#72D3FF" : "#1EABF1"}
                  hoverColor="#187DDA"
                  {...dragProps}
                >
                  Click or Drop here to upload {description}
                </StyledButton>
                {imageList.length && maxNumber > 1 ? (
                  <StyledButton
                    variant="contained"
                    size="large"
                    onClick={onImageRemoveAll}
                    myColor="#e74c3c"
                    hoverColor="#D14240"
                  >
                    Remove all images
                  </StyledButton>
                ) : null}
              </Stack>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <Stack
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {imageList.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Stack
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <img src={image["data_url"]} alt="" width="100" />
                            <IconButton
                              aria-label="edit"
                              onClick={() => onImageUpdate(index)}
                              style={{
                                maxWidth: "40px",
                                maxHeight: "40px",
                                minWidth: "40px",
                                minHeight: "40px",
                                alignSelf: "center",
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => onImageRemove(index)}
                              style={{
                                maxWidth: "40px",
                                maxHeight: "40px",
                                minWidth: "40px",
                                minHeight: "40px",
                                alignSelf: "center",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </Stack>
          </DragDropContext>
        )}
      </ImageUploading>
    </div>
  );
}
