// src/TerminateAccount.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';
import { StyledButton } from "./StyledComponents";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function TerminateAccount({ token }) {
  const [accountId, setAccountId] = useState('');
  const [confirmAccountId, setConfirmAccountId] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();
  const expectedConfirmationText = `Terminate the account ${accountId}`;

  const handleConfirmTerminate = async () => {
    setLoading(true); // Show loader

    const headers = {
      Accept: "*/*",
      token: token.token,
      "Content-Type": "application/json",
    };
    const body = {
      id: accountId,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/operations/delete_user_account`,
        body,
        { headers },
      );
      setLoading(false); // Hide loader
      setDialogOpen(false);
      setShowSuccess(true); // Show success snackbar
      setTimeout(() => {
        navigate('/');
      }, 2000); // Navigate after a short delay to allow user to see the success message
    }
    catch (error) {
      setLoading(false); // Hide loader
      setShowError(true); // Show error snackbar
      console.log(error);
    }
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <Stack
      spacing={4}
      alignItems="center"
      marginTop={4}
      marginBottom={8}
      paddingInline={7}
    >
      <Typography variant="h1" align="center" color="primary" fontSize={38}>
        Terminate Account
      </Typography>
      <TextField
        label="Account ID"
        variant="outlined"
        fullWidth
        value={accountId}
        onChange={(e) => setAccountId(e.target.value)}
      />
      <TextField
        label="Confirm Account ID"
        variant="outlined"
        fullWidth
        value={confirmAccountId}
        onChange={(e) => setConfirmAccountId(e.target.value)}
      />
      <StyledButton
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setDialogOpen(true)}
        disabled={!accountId.length || accountId !== confirmAccountId}
      >
        Terminate Account
      </StyledButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Termination</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To confirm termination, please type "{expectedConfirmationText}" below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Confirmation Text"
            fullWidth
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={confirmationText !== expectedConfirmationText || loading}
            onClick={handleConfirmTerminate}
            color="primary"
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? 'Processing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showError}
        autoHideDuration={2000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          An error occurred while terminating the account.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Account {accountId} has been terminated.
        </Alert>
      </Snackbar>
    </Stack>
  );
}
